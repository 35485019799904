import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { Author, Position } from '@sciflow/schema';
import * as AuthorActions from './author-management.actions';

export const authorFeatureKey = 'author-management';

export interface AuthorManagementState {
    authors: AuthorInUI[];
    selectedAuthor?: string | null;
    unsavedData: boolean;
    maximized: boolean;
    options: OptionState;
}

interface Funder {
    id: string;
    /*** ISO 3166-1 2-letter (US,CA,GB,..) */
    countryCode: string;
    institution: {
      id: string;
      name: string;
      type?: 'doi' | 'Ringgold' | 'ISNI';
    };
    awardIds: string[];
  }

export interface AuthorInUI extends Author {
    id: string;
    /** the author is editable by a logged in user / by anyone */
    editable?: boolean;
    /** the author is the user currently viewing */
    currentUser: boolean;
    /** true or false if the user is not online, null if the state is indeterminate */
    online?: boolean;
    /** A number for each user in the document (1-10) */
    userColor?: number;
    invitedAt: Date;
    authorId: string;
    picture?: {
        small?: {
            url: string
        }
    };
    type: 'author' | 'group';
    emailVerified?: boolean;
    kemail: string;
    twitter: string;
    orcid: string;
    positions: Position[];
    groups: any[];
    funders: Funder[];
    equalContribution: boolean;
    correspondingAuthor: boolean;
    rank: number;
    deceased: boolean;
    allowedRoles?: string[];
    hideInPublication: boolean;
}

export const initialState: AuthorManagementState = {
    authors: [],
    selectedAuthor: undefined,
    unsavedData: false,
    maximized: false,
    options: {}
};

const sortByRank = (a, b) => (a?.rank || 0) - (b?.rank || 0);

// TODO DS reorder arrau in updatePposition
const authorManagementReducer = createReducer(
    initialState,
    on(AuthorActions.reset, () => ({ ...initialState })),
    on(AuthorActions.maximized, (state) => ({ ...state, maximized: true })),
    on(AuthorActions.minimized, (state) => ({ ...state, maximized: false })),
    on(AuthorActions.markSaved, (state) => ({ ...state, unsavedData: false })),
    on(AuthorActions.markUnsaved, (state) => ({ ...state, unsavedData: true })),
    on(AuthorActions.toggleHideInPublication, (state, action) => ({ ...state, authors: [...state.authors.map((author) => {
        if (author.authorId === action.authorId) { author.hideInPublication = action.hide; }
        return author;
    })] })),
    on(AuthorActions.makeGroupContribution, (state, action) => ({ ...state, authors: [...state.authors.map((author) => {
        if (author.authorId === action.authorId) { author.type = 'group'; }
        return author;
    })] })),
    on(AuthorActions.authorSaved, (state, action) => action.author ? ({ ...state, authors: [...state.authors.filter((author) => author.authorId !== action.author.authorId), action.author].sort(sortByRank) }) : ({ ...state })),
    on(AuthorActions.initializeAuthors, (state, action) => ({ ...state, unsavedData: false, authors: action.authors, options: state.options })),
    on(AuthorActions.initializeUsers, (state, action) => ({ ...state, unsavedData: false, authors: state.authors, options: action.user })),
    on(AuthorActions.updateAuthor, (state, action) => ({ ...state, authors: [...state.authors.filter((author) => author.authorId !== action.author.authorId), action.author].sort(sortByRank) })),
    on(AuthorActions.setSelectedAuthor, (state, action) => ({ ...state, unsavedData: false, selectedAuthor: state.authors.some(a => a.authorId === action.authorId) ? action.authorId : null })),
    on(AuthorActions.addAuthor, (state, action) => ({ ...state, unsavedData: false, authors: [...state.authors, action.author].sort(sortByRank).map((a, i) => ({ ...a, rank: i })) })),
    on(AuthorActions.deleteAuthor, (state, action) => ({ ...state, unsavedData: false, authors: state.authors.filter(author => author.authorId !== action.authorId) })),
    on(AuthorActions.updatePosition, (state, action) => {
        const authors = [...state.authors];
        const item = authors.splice(action.previousIndex, 1);
        authors.splice(action.currentIndex, 0, item[0]);
        return { ...state, authors: authors.map((a, i) => ({ ...a, rank: i })) };
    })
);

export const selectAuthorManagementState = (state): AuthorManagementState => state['author-management'];

export const selectAuthor = createSelector(
    selectAuthorManagementState,
    (state: AuthorManagementState, { authorId }) => state.authors.find(author => author.authorId === authorId)
);

export const selectAuthors = createSelector(
    selectAuthorManagementState,
    (state: AuthorManagementState) => state.authors
);

export const selectSelectedAuthor = createSelector(
    selectAuthorManagementState,
    (state: AuthorManagementState) => state.selectedAuthor
);

export const selectUnsavedData = createSelector(
    selectAuthorManagementState,
    (state: AuthorManagementState) => state.unsavedData
);

export function reducer(state: AuthorManagementState | undefined, action: Action) {
    return authorManagementReducer(state, action);
}

// Initialize options

export interface user {
    userId: string;
}

export interface OptionState {
    activeDocumentContext?: boolean;
    userProfile?: any;
    user?: {
      userId: string;
      userName?: string;
      email?: string;
      // the JWT to authenticate the user
      token?: string;
    };
  }

export interface IAppState {
    options: OptionState;
}

export const getUser = createSelector(
    selectAuthorManagementState,
    (state: AuthorManagementState) => state.options[0]
);

export const getUserProfile = createSelector(
    selectAuthorManagementState,
    (state: AuthorManagementState) => state.options[0].userProfile
);
