import CSL from 'citeproc';
import { CitationCache, CSLReference, Citation, CitationIDNoteIndexPair, CitationItem, customDisplayFormat, CitationItemOptions } from './types';

const styleUrl = 'https://www.zotero.org/styles';
const localUrl = 'https://github.com/citation-style-language/locales/raw/6b0cb4689127a69852f48608b6d1a879900f418b/locales-';

export const getStyleXML = async (id = 'apa'): Promise<string> => {
    const res = await fetch(`${styleUrl}/${id}`);

    if (res.status === 200) {
        return res.text();
    }

    throw new Error('Could not load style');
}

export const getLocaleXML = async (locale = 'en-US') => {
    const res = await fetch(`${localUrl}${locale}.xml`);

    if (res.status === 200) {
        return res.text();
    }

    throw new Error('Could not load locale');
}

export const getCitationOptions = (format: customDisplayFormat): CitationItemOptions => {
    switch (format) {
        case 'author-only':
            return {
                'author-only': true,
                'suppress-author': undefined
            }
        case 'combined':
            return {
                'author-only': true,
                'suppress-author': true
            }
        case 'suppress-author':
            return {
                'author-only': undefined,
                'suppress-author': true
            }
    }

    return {
        'author-only': undefined,
        'suppress-author': undefined
    };
}

/**
 * A stateful reference renderer.
 */
export class ReferenceRenderer {

    public footnotes = false;

    /** The citeproc-js citation renderer */
    private citeproc = new CSL.Engine({
        retrieveLocale: () => this.localeXML,
        retrieveItem: (id: string) => {
            const item = this.references.find(ref => ref.id === id);
            // console.log(item);
            if (!item) {
                throw new Error('Could not find reference ' + id);
            }
            let item_with_date = JSON.parse(JSON.stringify(item));
            if (typeof item_with_date.issued === 'string') {
                let date:Array<string> = item_with_date.issued.split("-");
                item_with_date.issued = { "date-parts": [ date ] };
            } else {
                // Handle cases where issued is not a string or is missing
                item_with_date.issued = { "date-parts": [ [ "n.d." ] ] }; // or some other default value
            }
            return item_with_date;
        }
    }, this.styleXML);


    /** Gets the reference it's and note index of already cited references */
    private get pre() {
        return this.citeproc.registry.citationreg.citationByIndex.map((citation, index) => [citation.citationID, index]);
    }

    /** A cache of all rendered citations */
    private citations: {
        [citationID: string]: CitationCache
    } = {};

    constructor(private styleXML: string, private localeXML: string, private references: CSLReference[], options?: { format?: 'text' | 'html' }) {
        if (options?.format) {
            this.citeproc.setOutputFormat(options?.format);
        }
    }

    /** Renders a bibliography based on all cited references
     * since creating the renderer. */
    getBibliography(filter?: any): {
        references: string[];
        /** General formating and styling requirements */
        formatting: {
            maxoffset: number;
            entryspacing: number;
            linespacing: number;
            hangingindent: boolean;
            'second-field-align': boolean;
            bibstart: string;
            bibend: string;
            bibliography_errors: any[];
            /** IDs of all entries in order */
            entry_ids: string[][];
        };
    } {
        // TODO add filter e.g. to create two bibliogafies for web resources and monographs
        // @see https://citeproc-js.readthedocs.io/en/latest/running.html#makebibliography
        const [formatting, references] = this.citeproc.makeBibliography();
        return {
            references,
            formatting
        };
    }

    /**
     * Renders a citation in context of the current document
     * (e.g. rendering will change the render result for following references)
     */
    public renderCitation(citation: Citation): CitationCache {
        return this.addCitationAtEnd(citation);
    }

    /** Adds a citation to the end of the reference list (no pre or post needed) */
    addCitationAtEnd(citation: Citation) {
        return this.addCitationAtPos(citation, this.pre, []);
    }

    /**
     * Returns a rendered citation and the citation's id  for later reference (e.g. to retrieve an updated citation id string after all references have been processed)
     * (!) adding a citation can always affect previous citations (e.g. because same last names were causing ambiguations)
     */
    addCitationAtPos(citation: Citation, pre: CitationIDNoteIndexPair[], post: CitationIDNoteIndexPair[]): CitationCache {
        if (citation.citationItems[0]['suppress-author'] && citation.citationItems[0]['author-only']) {
            delete citation.citationItems[0]['suppress-author'];
            delete citation.citationItems[0]['author-only'];
            // TODO make combined format possible
        }

        const citationIndex = pre.length;
        const [result, citations] = this.citeproc.processCitationCluster(citation, pre, post);


        for (let processedCitation of citations) {
            this.citations[processedCitation[2]] = {
                index: processedCitation[0],
                renderedCitation: processedCitation[1],
                citationID: processedCitation[2]
            };
        }

        const citationResult = citations.find(([index]) => index === citationIndex);
        if (!citationResult) { throw new Error('Could not get citation from index'); }

        return this.citations[citationResult[2]];
    }

    /** Returns a citation that has previously been added */
    getCitation(citationID: string): CitationCache {
        return this.citations[citationID];
    }

    getBibliographyIdMap() {
        const { references, formatting } = this.getBibliographyFromAll();
        return references.map((plainCitation, index) => {
            const id = formatting.entry_ids?.[index]?.[0];
            // we only fetch the first id because we asume that
            // all references have been rendered with only one id
            return {
                id,
                plainCitation
            };
        });
    }

    /** Renders a citation without respect to previous or following citations
     * (!) This is very fast because rules for ibid and others are not used.
     * Do not use this to create an acurate citation in context of a document context.
     * This could be used in an editor context where it is important to understand what
     * reference was placed  but not necessarily how it is represented in the final publication.
     */
    public renderCitationOutOfContext(citationItems: CitationItem[]): string {
        return this.citeproc.makeCitationCluster(citationItems);
    }

    /**
     * Returns a bibliograpy of all references (even unused).
     */
    public getBibliographyFromAll() {
        this.citeproc.updateItems(this.references.map(ref => ref.id));
        return this.getBibliography();
    }
}

export class SourceField {
    /** Decodes a string in the legacy reference format to a citation. */
    public static fromString(s: string): Citation {
        if (typeof s !== 'string') {
            throw new Error(s + ' is not a string');
        }

        if (s.length === 0) {
            return { citationItems: [], properties: { noteIndex: 0 } };
        }

        if (s.indexOf('%5B') === 0) {
            // encoded [ in the beginning of the string
            s = decodeURI(s);
        }

        if (s.indexOf('[') === 0) {
            // [{  }]
            const info: CitationItem[] = JSON.parse(s);
            if (!Array.isArray(info)) {
                throw new Error('Source must be array but was ' + s);
            }

            // return { citationItems: [{ id: info.map((ref) => { return ref.id; }).toString() }], properties: { noteIndex: 0 } };
            return { citationItems: info.map((ref) => { return ref; }) as unknown as CitationItem[], properties: { noteIndex: 0 } };
        } else {
            return { citationItems: [{ id: s.split(',').filter((v: string) => v !== '').toString() }], properties: { noteIndex: 0 } };
        }
    }

    /** Creates a JSON serialization of all citation items in order */
    public static toString(citation: Citation): string {
        if (!citation) {
            return '';
        }

        return JSON.stringify(citation.citationItems).replace(/"/g, '\"');
    }
}